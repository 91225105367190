"use strict";

export const hideTabContent = (a, element) => {
  for (var i = a; i < element.length; i++) {
    element[i].classList.remove("show");
    element[i].classList.add("hide");
  }
};

export const showTabContent = (b, element) => {
  if (element[b].classList.contains("hide")) {
    element[b].classList.remove("hide");
    element[b].classList.add("show");

    // fix for starting animation for tabs, (+1px move then -1px move)
    $(window).scrollTop($(window).scrollTop() + 1);
    $(window).scrollTop($(window).scrollTop() - 1);
  }
};

// grab car model params and set in to main car image function
export const setModelParams = () => {
  // get all params
  var url = "";
  var wheelSize = $(".wheels-list li.active").data("wheel");
  var carColor = $(".owl-item div.active").data("color");
  var carEngineType = $(".engines-list li.active").data("engine");

  // create parms string
  url +=
    "/model-single.html?wheels=" +
    wheelSize +
    "&color=" +
    carColor +
    "&engine=" +
    carEngineType;

  // set to main model image
  $(".model-image").attr("href", url);
};

/**
 * toggleDisableFormButton
 *
 * Allow or deny sending form,
 * need check checkbox for site privacy
 *
 * @param forms
 */
export const toggleDisableFormButton = (forms) => {
  forms.forEach(function (form, i, arr) {
    if ($(form).length) {
      var testDriveFormButton = $(form).find("button"),
        agreementCheckBox = $(form).find('input[type="checkbox"]');

      // set disabled button by default
      $(testDriveFormButton).prop("disabled", true);

      $(agreementCheckBox).on("click", function () {
        if ($(this).is(":checked")) {
          // remove attr disable
          $(testDriveFormButton).prop("disabled", false);
          $(testDriveFormButton)
            .removeClass("btn-disabled")
            .addClass("btn-primary");
        } else {
          // set disabled button by default
          $(testDriveFormButton).prop("disabled", true);
          $(testDriveFormButton)
            .removeClass("btn-primary ")
            .addClass("btn-disabled");
        }
      });
    }
  });
};

/**
 * General fade in effect for content function
 */
export const fadeEffectForContent = () => {
  var elements;
  var windowHeight;

  function init() {
    elements = document.querySelectorAll(".fade-effect");
    windowHeight = window.innerHeight;
  }

  function checkPosition() {
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      var positionFromTop = elements[i].getBoundingClientRect().top;

      if (positionFromTop - windowHeight <= 0) {
        element.classList.add("fade-in-element");
        element.classList.remove("fade-effect");
      }
    }
  }

  window.addEventListener("scroll", checkPosition);
  window.addEventListener("resize", init);

  init();
  checkPosition();
};

// Start close alert messages
export const closeAlertMessages = (selector = "closebtn") => {
  let close = document.getElementsByClassName(selector);

  // Loop through all close buttons
  for (let i = 0; i < close.length; i++) {
    // When someone clicks on a close button
    close[i].onclick = function () {
      // Get the parent of <span class="closebtn"> (<div class="alert">)
      var div = this.parentElement;

      // Set the opacity of div to 0 (transparent)
      div.style.opacity = "0";

      // Hide the div after 600ms (the same amount of milliseconds it takes to fade out)
      setTimeout(function () {
        div.style.display = "none";
      }, 600);
    };
  }
};

export const animationsForComments = (selector = ".comment") => {
  let commentTimer = 100;
  $(selector).each(function (el) {
    $(el).addClass("wow fadeInLeft").attr("data-wow-delay", commentTimer);
    commentTimer += 100;
  });
};

/**
 * Change class of html div,
 * on mobile container-fluid, on desktop container
 *
 * @param elementId
 */
export const containerToContainerFluid = (elementId) => {
  if ($(window).width() < 767) {
    $(elementId).removeClass("container").addClass("container-fluid");
  } else {
    $(elementId).removeClass("container-fluid").addClass("container");
  }
};
