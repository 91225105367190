"use strict";
import { hideTabContent, showTabContent, setModelParams } from "./utilities";

// Start model viewver tabs
if (
  $(".model-viever-tabs-list").length &&
  $(".model-viever--tab").length &&
  $(".model-viever-tab--content").length
) {
  var modelViewverTabsParent = document.querySelector(
      ".model-viever-tabs-list"
    ),
    modelViewverTabs = document.querySelectorAll(".model-viever--tab"),
    modelVieverTabsContent = document.querySelectorAll(
      ".model-viever-tab--content"
    );

  // hide all model tab content exept first one
  hideTabContent(1, modelVieverTabsContent);

  // add event listener to click by color item and showing current content tab
  modelViewverTabsParent.addEventListener("click", function (event) {
    event.preventDefault();

    var target = event.target;

    if (target && target.classList.contains("model-viever--tab")) {
      // remove all avtive classes from tabs
      $(".model-viever--tab").closest("li").removeClass("active");
      // add target active class
      $(target).closest("li").addClass("active");

      for (var i = 0; i < modelViewverTabs.length; i++) {
        if (target == modelViewverTabs[i]) {
          // hide all model tab content
          hideTabContent(0, modelVieverTabsContent);

          // show special tab content
          showTabContent(i, modelVieverTabsContent);

          break;
        }
      }
    }
  });
}
// End model viewver tabs

// Start model tabs
if (
  $(".color-filter").length &&
  $(".model-tab").length &&
  $(".model-tab-content").length
) {
  var modelTabContent = document.querySelectorAll(".model-tab-content"),
    modelTab = document.querySelectorAll(".model-tab"),
    colorFilter = document.querySelector(".color-filter");

  // hide all model tab content exept first one
  hideTabContent(1, modelTabContent);

  // add event listener to click by color item and showing current content tab
  colorFilter.addEventListener("click", function (event) {
    var target = event.target;

    if (target && target.classList.contains("model-tab")) {
      // remove all avtive classes from tabs
      $(".model-tab").removeClass("active");
      // add target active class
      $(target).addClass("active");

      for (var i = 0; i < modelTab.length; i++) {
        if (target == modelTab[i]) {
          // hide all model tab content
          hideTabContent(0, modelTabContent);

          // show special tab content
          showTabContent(i, modelTabContent);

          // set model parms to car main image
          setModelParams();

          break;
        }
      }
    }
  });
}
// End model tabs

// Start tabs
if ($("#tabs").length) {
  $("#tabs li a:first").addClass("active");
  $("#tabs li a:not(:first)").addClass("inactive");
  $(".tab-content").hide();
  $(".tab-content:first").show();

  $("#tabs li a").click(function (e) {
    e.preventDefault();
    var t = $(this).attr("id");
    if ($(this).hasClass("inactive")) {
      //this is the start of our condition
      $("#tabs li a").addClass("inactive").removeClass("active");
      $(this).removeClass("inactive").addClass("active");

      $(".tab-content").hide();
      $("#" + t + "C").fadeIn("slow");
    }
  });
}
// End tabs
