"use strict";

undefined;

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

// basics imports
import WOW from "wow.js/dist/wow.js";
let Typed = require("typed.js");
require("lightbox2");
import customSelect from "custom-select";
require("./libs/jquery.countTo.js");

/* =====================================
    UTILITIES
/* =====================================*/
import {
  setModelParams,
  toggleDisableFormButton,
  fadeEffectForContent,
  closeAlertMessages,
  animationsForComments,
  containerToContainerFluid,
} from "./modules/utilities";

/* =====================================
    WINDOW ON LOAD
/* =====================================*/
window.addEventListener("load", function () {
  document.querySelector("body").classList.remove("loading");
  document.querySelector("body").classList.add("loaded");
});
$(window).on("load", function () {
  // Add class fade to each of sidebar widget
  $(".sidebar-widget").each(function (key, el) {
    $(el).addClass("fade-effect");
  });

  // General fade in effect for content
  fadeEffectForContent();
});
/* =====================================
    WINDOW ON SCROLL
/* =====================================*/

/* =====================================
    DOCUMENT ON READY
/* =====================================*/
$(document).on("ready", () => {
  // Include module for carousels
  require("./modules/carousel-module");

  // Include module for popups window
  require("./modules/popup-module");

  // Include module tabs
  require("./modules/tabs-module");

  // Include module accordion
  require("./modules/accordion-module");

  // Include module masonry grid
  require("./modules/masonry-grid-module");

  // Include module navigation
  require("./modules/nav-module");


  $('.counter').countTo({
    refreshInterval: 50,
    formatter: function (value, options) {
      value = value.toFixed(options.decimals);
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return value;
    }
  });


  $(window).scroll(function () {
    if ($(window).scrollTop() > topHeight) {
      $("#scrollTop").addClass("active");
    } else {
      $("#scrollTop").removeClass("active");
    }
  });

  // Custom select
  customSelect(".material-custom-select");

  // Typed string at model-overview-widget
  if ($(".typed").length && $("#typed-strings").length) {
    new Typed(".typed", {
      stringsElement: "#typed-strings",
      loop: true,
      typeSpeed: 60,
      backSpeed: 60,
      startDelay: 1200,
      backDelay: 1500,
    });
  }

  // Init toggleDisableFormButton
  toggleDisableFormButton([
    "#test-drive-inner .test-drive-form",
    "#call-now-inner .call-now-form",
    "#order-consultation-inner .order-consultation-form",
  ]);

  // Scroll to top
  let topHeight = $("#header").outerHeight();

  $("#scrollTop").on("click", function () {
    $("html, body").animate({ scrollTop: "0px" }, 200);
  });

  // init setModelParams when widget model-builder-widget loaded
  if ($(".model-builder-widget .model-image").length) {
    // set model parms to car main image
    setModelParams();
  }

  // Model engine changing active tab
  $(document).on("click", ".engines-list li", function (event) {
    event.preventDefault();

    $(".engines-list li").removeClass("active");
    $(this).closest("li").addClass("active");

    // set model parms to car main image
    setModelParams();
  });

  // Advantages box change active item
  $(document).on("click", ".advantage-item", function (event) {
    event.preventDefault();

    $(".advantage-item").removeClass("active");
    $(this).addClass("active");
  });

  // Model wheel changing active tab
  $(document).on("click", ".wheels-list li", function (event) {
    $(".wheels-list li").removeClass("active");
    $(this).closest("li").addClass("active");

    // set model parms to car main image
    setModelParams();
  });

  // Add animation for comments
  animationsForComments(".comment");

  // Wrap all tables with class table in post-content for mobile responsive
  $(".post-content .table").wrap('<div class="table-responsive"></div>');

  // Start close alert messages
  closeAlertMessages();

  //init wow.js
  new WOW().init();

  $(".posts-categories-list li a").click(function (e) {
    $(".posts-categories-list li a.active").removeClass("active");

    $(this).addClass("active");
    e.preventDefault();
  });

  // responsive iframe
  if ($("iframe").length) {
    $("iframe")
      .wrap('<div class="responsive-iframe"></div>')
      .removeAttr("width height");
  }

  // full width p for p > img just only for desktop
  if (window.matchMedia("(min-width: 992px)").matches) {
    $("p:has(img:first)").css({ "max-width": "100%" });
  }

  // writing number for ol li
  $(".ordered-list > li").each(function () {
    $(this).prepend(
      "<span class='list-number'>" + ($(this).index() + 1) + "." + "</span>"
    );
  });

  // No click
  $(".no-click").on("click", function (e) {
    e.preventDefault();
  });

  // init containerToContainerFluid
  containerToContainerFluid("#contact-map-wrap");

  // containerToContainerFluid on resize event
  window.onresize = function () {
    containerToContainerFluid("#contact-map-wrap");
  };
});

/**
 * Replace all SVG images with inline SVG
 */
jQuery("img.svg").each(function () {
  var $img = jQuery(this);
  var imgID = $img.attr("id");
  var imgClass = $img.attr("class");
  var imgURL = $img.attr("src");

  jQuery.get(
    imgURL,
    function (data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find("svg");

      // Add replaced image's ID to the new SVG
      if (typeof imgID !== "undefined") {
        $svg = $svg.attr("id", imgID);
      }
      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== "undefined") {
        $svg = $svg.attr("class", imgClass + " replaced-svg");
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr("xmlns:a");

      // Replace image with new SVG
      $img.replaceWith($svg);
    },
    "xml"
  );
});
