"use strict";
import $ from "jquery";
import imagesLoaded from "imagesloaded";
const lightSlider = require("./../libs/lightslider");
require("jquerykeyframes");
import "slick-carousel";
require("../libs/slick-animation");

let owl_carousel = require("owl.carousel");
window.fn = owl_carousel;

// new-arrivals widget
$(".new-arrivals-slider").slick({
  //infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  //autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
});

// car presentation widget
$(".car-presentation-slider").on("init", function (event, slick) {
  var dots = $(".slick-dots li");
  dots.each(function (k, v) {
    // add animations to menu item
    $(this)
      .addClass("wow fadeInUp")
      .attr("data-wow-delay", `${k + 1}00ms`);

    $(this)
      .find("button")
      .addClass("dots-text" + k);
  });
  var items = slick.$slides;
  items.each(function (k, v) {
    var text = $(this).find(".slide__item").attr("data-brand");
    $(".dots-text" + k).text(text);
  });
});
$(".car-presentation-slider").slick({
  dots: true,
  focusOnSelect: true,
  infinite: true,
  arrows: false,
  speed: 300,
  slidesToShow: 1,
});

// content preview slider widget
if ($(".content__preview__slider").length) {
  $(".content__preview__slider")
    .slick({
      autoplay: false,
      speed: 800,
      lazyLoad: "progressive",
      arrows: false,
      dots: true,
    })
    .slickAnimation();
}

$.keyframe.define([
  {
    name: "slideLoading",
    "0%": {
      height: "0",
    },
    "100%": {
      height: "100%",
    },
  },
]);

// carousel at testimonials widget
$(".testimonials-box").owlCarousel({
  loop: false,
  dots: true,
  items: 1,
  autoplay: false,
  autoplayTimeout: 10000,
  autoplayHoverPause: true,
  nav: false,
  navText: [
    '<svg width="14" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>',
    '<svg width="14" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>' /* icons from https://iconmonstr.com */,
  ],
});

// colors carousel model builder widget
$(".color-filter").owlCarousel({
  loop: false,
  dots: false,
  autoplay: false,
  autoplayTimeout: 10000,
  autoplayHoverPause: true,
  nav: true,
  navText: [
    '<svg width="14" height="23" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>',
    '<svg width="14" height="23" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>' /* icons from https://iconmonstr.com */,
  ],
  responsive: {
    320: {
      items: 3,
    },
    375: {
      items: 4,
    },
    575: {
      items: 5,
    },
    768: {
      items: 5,
    },
    992: {
      items: 3,
    },
    1100: {
      items: 4,
    },
    1200: {
      items: 4,
    },
    1440: {
      items: 4,
    },
    1780: {
      items: 5,
    },
  },
});

// init Slider for new model widget
if ($("#new-model-vertical-list").length) {
  var container = $("#new-model-vertical-list");

  container.imagesLoaded(function () {
    // new models slider at model page, use lightslider
    $("#new-model-vertical-list").lightSlider({
      gallery: true,
      item: 1,
      vertical: true,
      verticalHeight: 131,
      vThumbWidth: 195,
      thumbItem: 4,
      thumbMargin: 10,
      slideMargin: 0,
      controls: false,
      auto: true,
      pause: 15000,
    });
  });
}

// init Owl Carousel full width slider
/*JS way for setting height: 100vh to slides' height*/
// const $slides = $(".owl-carousel .owl-slide");
// $slides.css("height", $(window).height());
// $(window).resize(() => {
//   $slides.css("height", $(window).height());
// });

$("#header-slide").on("initialized.owl.carousel", function () {
  setTimeout(function () {
    $(".owl-item.active .owl-slide-animated").addClass("is-transitioned");
    $("section").show();
  }, 200);
});

var owlCarousel = $("#header-slide").owlCarousel({
  items: 1,
  loop: false,
  nav: true,
  dots: true,
  autoplay: false,
  autoplayTimeout: 10000,
  autoplayHoverPause: true,
  navText: [
    '<svg width="15" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>',
    '<svg width="15" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>' /* icons from https://iconmonstr.com */,
  ],
});

owlCarousel.on("changed.owl.carousel", function (e) {
  $(".owl-slide-animated").removeClass("is-transitioned");

  var currentOwlItem = $(".owl-item").eq(e.item.index);
  currentOwlItem.find(".owl-slide-animated").addClass("is-transitioned");
});

/*if there isn't content underneath the carousel*/
owlCarousel.trigger("refresh.owl.carousel");

// Start models slider
$("#models-slider").owlCarousel({
  loop: true,
  dots: false,
  items: 1,
  autoplay: false,
  autoplayTimeout: 10000,
  autoplayHoverPause: true,
  nav: true,
  navText: [
    '<svg width="16" height="26" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>',
    '<svg width="16" height="26" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>' /* icons from https://iconmonstr.com */,
  ],
  responsive: {
    575: {
      items: 1,
    },
    768: {
      items: 2,
      margin: 40,
    },
    992: {
      items: 2,
      margin: 40,
    },
    1200: {
      items: 3,
      margin: 40,
    },
    1440: {
      items: 3,
      margin: 65,
    },
  },
});

// enable owl-nav fix
$("#models-slider").find(".owl-nav").removeClass("disabled");
$("#models-slider").on("changed.owl.carousel", function (event) {
  $(this).find(".owl-nav").removeClass("disabled");
});

// models slider, enable hover effect at screens bigger 992px
if (window.matchMedia("(min-width: 992px)").matches) {
  //models slider model detail box showing
  $(".models-slider a").hover(
    function () {
      $(this).find(".model-details").fadeIn();
    },
    function () {
      $(this).find(".model-details").fadeOut();
    }
  );
}

/* ==========================
    Start Members slider section
    ============================*/

// members slider var inits
var membersSlider = $(".members-slider"),
  membersSliderTimeIteration = 20000,
  membersSliderOptions = {
    center: true,
    autoWidth: false,
    stagePadding: 0,
    loop: true,
    margin: 25,
    items: 1,
    navigation: false,
    mouseDrag: false,
    autoplay: true, // customer can enable of disable autoplay, default enable
    autoplayTimeout: membersSliderTimeIteration, // customer can change value of it, default 5s,
    autoplayHoverPause: true,
    nav: false,
    dots: false,
    onInitialized: function () {
      // enable slider line animation
      if (membersSliderOptions.autoplay == true) {
        $(".separator > span").addClass("active-slide");
        $(".separator > span").playKeyframe({
          name: "slideLoading",
          duration: membersSliderTimeIteration + "ms",
          timingFunction: "linear",
          iterationCount: "infinite",
          direction: "normal",
          fillMode: "forwards",
          complete: function () {
            $(".separator > span").removeClass("active-slide");
          },
        });
      }

      // check classes
      checkClasses();

      // set new info about member
      showMemberInfo();

      // init conuter helper
      counter;
    },
    onChanged: counter,
    responsive: {
      620: {
        items: 3,
        margin: 15,
      },
      768: {
        items: 3,
        margin: 15,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };

// init members slider
membersSlider.owlCarousel(membersSliderOptions);

// marked first and last slides
function checkClasses() {
  var total = $(".members-slider .owl-stage .owl-item.active").length;

  $(".members-slider .owl-stage .owl-item").removeClass(
    "firstActiveItem lastActiveItem"
  );

  $(".members-slider .owl-stage .owl-item.active").each(function (index) {
    if (index === 0) {
      // this is the first one
      $(this).addClass("firstActiveItem");
    }
    if (index === total - 1 && total > 1) {
      // this is the last one
      $(this).addClass("lastActiveItem");
    }
  });
}

// show member hidden info
function showMemberInfo() {
  $(".member-details").html("");

  if (window.matchMedia("(max-width: 574px)").matches) {
    $(".member-details").append($(".center .hidden-data").html());
  } else {
    $(".member-details").append($(".lastActiveItem .hidden-data").html());
  }
}

// owl event after slider translated
membersSlider.on("translated.owl.carousel", function (event) {
  checkClasses();

  showMemberInfo();
});

// Members slider line animation, work only slider param `autoplay`: true
if (membersSliderOptions.autoplay == true) {
  membersSlider.on("translated.owl.carousel", function (event) {
    // enable slider line animation
    $(".separator > span").addClass("active-slide");
    $(".separator > span").playKeyframe({
      name: "slideLoading",
      duration: membersSliderTimeIteration + "ms",
      timingFunction: "ease",
      iterationCount: "infinite",
      direction: "normal",
      fillMode: "forwards",
      complete: function () {
        $(".separator > span").removeClass("active-slide");
      },
    });
  });
}

// event click next slide
$(document).on("click", "#next-slide", function () {
  membersSlider.trigger("next.owl.carousel");
});
// event click prev slide
$(document).on("click", "#prev-slide", function () {
  membersSlider.trigger("prev.owl.carousel");
});

// slider counter
function counter(event) {
  var element = event.target; // DOM element, in this example .owl-carousel
  var items = event.item.count; // Number of items
  var item = event.item.index + 1; // Position of the current item

  // it loop is true then reset counter from 1
  if (item > items) {
    item = item - items;
  }
  $(".current-slide-number").html(("0" + item).slice(-2));
}

/* ==========================
    End Members slider section
    ============================*/
