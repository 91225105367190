"use strict";
import $ from "jquery";
import jQueryBridget from "jquery-bridget";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

jQueryBridget("masonry", Masonry, $);
jQueryBridget("imagesLoaded", imagesLoaded, $);

if ($(".advantages-box").length) {
  var container = $(".advantages-box");

  container.imagesLoaded(function () {
    container.masonry({
      itemSelector: ".grid-item",
      columnWidth: 1,
      horizontalOrder: true,
    });
  });
}

// init masonry grid models gallery
if ($(".project-gallery").length) {
  var container = $(".project-gallery");

  container.imagesLoaded(function () {
    container.masonry({
      columnWidth: ".grid-sizer",
      itemSelector: ".grid-item",
      gutter: ".gutter-sizer",
      percentPosition: true,
    });
  });
}

// init masonry grid blog posts
if ($(".posts-masonry").length) {
  var container = $(".posts-masonry");

  container.imagesLoaded(function () {
    container.masonry({
      columnWidth: ".grid-sizer",
      itemSelector: ".grid-item",
      gutter: ".gutter-sizer",
      percentPosition: true,
    });
  });
}
