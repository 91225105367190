"use strict";

// Open N tab by default
$(".project-accordion li:nth-child(4) .toggle").toggleClass("active");
$(".project-accordion li:nth-child(4) .inner")
  .toggleClass("show")
  .slideToggle(350);

// wrap all .inner > p in div
$(".project-accordion > li").each(function () {
  $(this)
    .find(".inner p")
    .wrapAll("<div class='accordion-paragraphs-wrapper'></div>");
});

// Accordion tabs
$(".toggle").click(function (e) {
  e.preventDefault();

  var $this = $(this);

  if ($this.next().hasClass("show")) {
    $this.next().removeClass("show");
    $this.next().slideUp(350);
  } else {
    $this.parent().parent().find("li .inner").removeClass("show");
    $this.parent().parent().find("li .inner").slideUp(350);
    $this.next().toggleClass("show");
    $this.next().slideToggle(350);
  }

  $(this).toggleClass("active");
});
